import { useSalesRoomDict,useDesDict,useTemDict,useHouseAreasDict,useHousetypeDict,useDesStyleDict,useDesRankDict } from '@/utils/frequent.js'
import {mapState} from 'vuex'
export default {
	data(){
		return {
			salesRoomDict:[],
			desStyleDict:[],
			housetypeDict:[],
			houseAreasDict:[],
            ranksDict:[],
		}
	},
    async mounted(){
        this.salesRoomDict = await useSalesRoomDict()
        this.desStyleDict = await useDesStyleDict()
        this.housetypeDict = await useHousetypeDict()
        this.houseAreasDict = await useHouseAreasDict()
        this.ranksDict = await useDesRankDict()
    },
    computed:{
        ...mapState(['curCityInfo'])
    },
	methods: {
		formartData(type,value){
            if(type=='store'){
                var re = ''
                this.salesRoomDict.forEach((obj) => {
                    if (obj.showRoomNo === value) {
                        re = obj.showRoomName
                    }
                })
                return re
            }
            if(type=='desStyle'){
                var re = ''
                this.desStyleDict.forEach((obj) => {
                    if (obj.value === value) {
                        re = obj.label
                    }
                })
                return re
            }
            if(type=='houseStyle'){
                var re = ''
                this.housetypeDict.forEach((obj) => {
                    if (obj.value === value) {
                        re = obj.label
                    }
                })
                return re
            }
            if(type=='area'){
                var re = ''
                this.houseAreasDict.forEach((obj) => {
                    if (obj.value == value) {
                        re = obj.label
                    }
                })
                return re
            }
            if(type=='ranks'){
                const res = this.ranksDict.find(item=>item.value==value)
                if(res) return res.label
                else return value
            }
        },
        setCity(){
            const list = [this.curCityInfo.value]
            this.city = this.cityStoreList.map(item=>{
                if(item.value==this.curCityInfo.value) list.push(item.children[0].value)
            })
            this.city = list
        },
	}
}