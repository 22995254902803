<template>
    <transition name="el-fade-in" >
        <div v-show="show" class="visitor-form3" >
            <!-- <div class="seat"></div> -->
            <div class="content f-x-c-c">
                <div class="mask"></div>
                <img @click="navToPage" class="img" src="../image/common/footer_form.png" alt="">
                <div class="form-box f-y-c-s">
                    <div class="title f-x-c-c">
                        <span class="text1">看看我家装修需要多少钱？</span>
                        <span class="text2">【已有 49521 人免费获取装修报价】</span>
                    </div>
                    <div class="form f-x-c-c">
                        <el-form
                            :inline="true" 
                            ref="ruleFormRef"
                            :model="visitorInfo"
                            :rules="rules"
                            class="cus-form"
                        >
                            <el-form-item  >
                                <el-cascader
                                    class="input city"
                                    placeholder="所在城市"
                                    size="default"
                                    :options="cityStoreList"
                                    v-model="city"
                                >
                                </el-cascader>
                            </el-form-item>
                            <el-form-item   prop="houseArea">
                                <el-input
                                    class="input"
                                    placeholder="房屋面积"
                                    v-model="visitorInfo.houseArea"
                                    inputmode="numeric"
                                >
                                    <div slot="suffix" class="m2">m²</div>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="mobile" >
                                <el-input
                                    class="input phone"
                                    placeholder="联系方式"
                                    v-model="visitorInfo.mobile"
                                    inputmode="numeric"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item >
                                <div @click="submitForm" class="btn f-x-c-c">查询报价</div>
                            </el-form-item>
                       
                        </el-form>
                    </div>
                </div>
                <img @click="close" class="close-btn" src="../image/common/footer_form_close.png" alt="">
            </div>
            <MessageForm ref="refMessage" :list="errList"></MessageForm>
        </div>
    </transition>
</template>
<script>
import { postmobile } from '@/api/views/name.js'
import {useCityInfo} from '@/utils/frequent.js'
export default {
	props: {
      
	},
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback(new Error('请输入正确的电话号码'));
            }else{
                callback();
            }
            
        };
		return {
            
            fullHeight: null,
            show:false,
            isClose:false,
            city:[],
            cityStoreList:[],
            timer:null,
			visitorInfo: {
				houseArea: '',
				mobile: '', //电话
				name: '',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: 'ONhz', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
			},
            rules:{
                // houseArea: [
                //     { required: true, message: ' ', trigger: 'change' },
                // ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            errList:[],
            timer2:''
		}
	},
	async mounted() {
        const path = this.$route.path
        // this.show = (path!='/') 
		this.setAccessInfo()
        this.cityStoreList = await useCityInfo()
        if(this.curCityInfo.value!='00') this.setCity()
        if(path=='/' || path=='/zz' || path=='sh'){
            window.fullHeight = document.documentElement.clientHeight;
            this.fullHeight = window.fullHeight;


            this.show = false
            var validatePhone = (rule, value, callback) => {
                if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                    callback(new Error('请输入正确的电话号码'));
                }else{
                    callback();
                }
                
            };
            this.rules = {
                houseArea: [
                    { required: true, message: ' ', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            }
            this.$nextTick(()=>{
                this.$refs.ruleFormRef.clearValidate(); // 清除之前的验证状态
            })
            

        }else {
            this.fullHeight = 0;
            this.show = true
        }
        window.onresize = () => {
            return (() => {
                if(path=='/' || path=='/zz' || path=='sh'){
                    window.fullHeight = document.documentElement.clientHeight;
                    this.fullHeight = window.fullHeight;
                }else this.fullHeight = 0;
            })();
        };
        window.addEventListener("scroll", this.scrollToTop);
    },
	methods: {
        close(){
            clearTimeout(this.timer)
            this.isClose = true
            this.show = false
            this.timer = setTimeout(()=>{
                this.isClose = false
                this.show = true
            },16000)
        },
		setAccessInfo() {
			this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
			this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
			this.visitorInfo.reqUrl = window.location.href

            this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
            this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
            this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
            this.visitorInfo.projectName =  this.$Cookie.get('projectName')
            this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
		},
        navToPage(){
            this.$router.push({
                path:'/quotation'
            })
        },
        scrollToTop() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (!this.isClose) {
                if (scrollTop >= this.fullHeight) {
                    this.show = true;
                } else if (scrollTop <= this.fullHeight) {
                    this.show = false;
                }
            }
        },
        async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    console.log("aa",obj);
                    if(valid){
                        if(this.city.length){
                            this.visitorInfo.cityCode = this.city[0]
                            this.visitorInfo.salesRoomNo = this.city[1]
                        }

                        const res = await postmobile(this.visitorInfo)
                        this.$refs.refMessage.showDialog('sucess')
                        this.city=[]
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        clearTimeout(this.timer2)
                        this.timer2 = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        console.log("obj",obj);
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res
                        clearTimeout(this.timer2)
                        this.timer2 = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
		// async submit() {
		// 	try {
		// 		if (
		// 			!this.visitorInfo.mobile.match(
		// 				/^[1][3,4,5,6.7,8,9][0-9]{9}$/
		// 			)
		// 		) {
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message: '请输入正确的手机号',
		// 				type: 'warning',
		// 			})
		// 			// this.visitorInfo.mobile = ''
		// 		} else {
        //             if(this.city.length){
		// 			    this.visitorInfo.cityCode = this.city[0]
        //                 this.visitorInfo.salesRoomNo = this.city[1]
        //             }

		// 			const res = await postmobile(this.visitorInfo)
        //             this.city=[]
		// 			this.visitorInfo.mobile = ''
		// 			this.visitorInfo.provinceCode = ''
		// 			this.visitorInfo.cityCode = ''
		// 			this.visitorInfo.houseArea = ''
        //             this.visitorInfo.name = ''
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message:
		// 					'提交成功！稍后家装顾问与您联系，请注意021开头电话',
		// 				duration: 2000,
		// 				offset: 120,
		// 			})
		// 		}
		// 	} catch (err) {
		// 		console.log(err)
		// 	}
		// },
	},
    beforeDestroy(){
        clearTimeout(this.timer)
        clearTimeout(this.timer2)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .visitor-form3{
        display: none;
    }
}
@media screen and (min-width: 900px) {
	.visitor-form3 {
        z-index: 99;
        position: relative;
        .seat{
            height: 116px;
            width: 100%;
            background: #190039;
        }
        .content{
            position: fixed;
            bottom: 0;
            left: 0;
            height: 116px;
            
            width: 100%;
            padding: 0 300px;
            box-sizing: border-box;
            z-index: 99;
            .mask{
                position: absolute;
                left: 0;
                top: 0;
                background: #0D031A;
                // background: rgba(13, 3, 26, 0.88);
                opacity: 0.8;
                width: 100%;
                height: 100%;
            }
            .img{
                cursor: pointer;
                width: 226px;
                // height: 140px;
                margin-bottom: 50px;
                margin-right: 30px;
                z-index: 99;
                object-fit: cover;
            }
            .form-box{
                z-index: 99;
                .title{

                    .text1{
                        
                        font-weight: bold;
                        font-size: 26px;
                        color: #FFFFFF;
                        line-height: 26px;
                    }
                    .text2{
                        
                        font-weight: 400;
                        font-size: 18px;
                        color: #FFFFFF;
                        line-height: 18px;
                        margin-left: 15px;
                        margin-top: 3px;
                    }
                }
                .form{
                    margin-top: 8px;
                    width: 100%;
                    .cus-form{
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        .el-form-item{
                            margin-right: initial !important;
                            margin-bottom: initial !important;
                        }
                    }
                    .input{
                        width: 240px;
                        margin-right: 30px;
                        /deep/ .el-input{
                            width: 100%;
                            height: 54px;
                            
                            .el-input__inner{
                                width: 100%;
                                height: 54px;
                            }
                        }
                        /deep/ .el-input__inner{
                            width: 100%;
                            height: 54px;
                            font-size: 22px !important;
                        }
                        
                        
                        /deep/.el-input__suffix{
                            right: 20px;
                            .m2{
                                height: 54px;
                                line-height: 54px;
                                font-size: 22px;
                            }
                        }
                        /deep/.el-icon-arrow-down{
                            font-size: 22px !important;

                        }
                    }
                    .el-cascader{
                        /deep/.el-input__suffix{
                            right: 27px !important;
                        }
                    }
                    .btn{
                        width: 240px;
                        height: 54px;
                        background: #F6900C;
                        border-radius: 3px;
                        
                        font-weight: bold;
                        font-size: 28px;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
            }
            .close-btn{
                position: absolute;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                top: 10px;
                right: 200px;
                cursor: pointer;
            }
        }
        
        
        
    }
}
</style>
