// import 'babel-polyfill'
import Cookie from 'js-cookie'
import Vue from 'vue'
// 引入elementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import store from './store'
import router from './router'
//拖拽样式
import 'splitpanes/dist/splitpanes.css'
// 引入rem响应式
import '@/utils/rem.js'

// 引入animate样式
import 'animate.css';
// import '@/assets/styles/common.scss'
// 引入swiper样式
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

Vue.component('Swiper',Swiper)
Vue.component('SwiperSlide',SwiperSlide)

import RightMenu from  '@/components/RightMenu.vue'
Vue.component('RightMenu',RightMenu)

import Pagination from  '@/components/Pagination.vue'
Vue.component('Pagination',Pagination)

import DialogForm from  '@/components/DialogForm.vue'
Vue.component('DialogForm',DialogForm)

import MessageForm from  '@/components/MessageForm.vue'
Vue.component('MessageForm',MessageForm)

import Floating from  '@/components/Floating.vue'
Vue.component('Floating',Floating)

import VisitorForm3 from  '@/components/VisitorForm3.vue'
Vue.component('VisitorForm3',VisitorForm3)


import FloatingMob from  '@/components/FloatingMob.vue'
Vue.component('FloatingMob',FloatingMob)

import Tabbar from  '@/components/Tabbar.vue'
Vue.component('Tabbar',Tabbar)

import Mixin from '@/mixins/index.js';
Vue.mixin(Mixin);
// 百度的统计代码
// eslint-disable-next-line no-use-before-define
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?5187ab9825d3ac6cc7da938760583446";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

// import Es6Promise from 'es6-promise'


// 引入百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(ElementUI)

// 引入rem
// import 'lib-flexible'

// 使用cookie
Vue.prototype.$Cookie = Cookie

// 注册一个全局的vue title指令
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = binding.value
    // console.log(binding)
  }
})
// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.innerText
//     el.remove()
//   }
// })

// 引入es6语法
// require('es6-promise').polyfill()
// Es6Promise.polyfill()

// eventBus 事件车
Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false

// 使用百度地图
Vue.use(BaiduMap, {
  ak: 'q4ehkqSW3elZfq5pAGbcmOOss8VA5hsd'
  //  在此输入你自己的百度地图ak
})

// 路由守卫
router.afterEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

