<template>
	<el-drawer
		:visible.sync="showMenu"
		:wrapperClosable="true"
		:append-to-body="true"
		:withHeader="false"
		size="100%"
		direction="rtl"
		@close="closeMenu"
	>
		<div class="menu">
			<el-collapse v-model="activeName" accordion>
                <div 
                    v-for="(item, index) in menuList"
				    :key="index" 
                >
                     <el-collapse-item v-if="item.children && item.children.length" :name="index">
                        <template slot="title">
                            <div class="box f-x-b-c">
                                <span class="title">{{item.title}}</span>
                                <i v-if="activeName == index" class="el-icon-minus"></i>
                                <i v-else class="el-icon-plus"></i>
                            </div>
                        </template>
                        <div
                            v-for="(it, index) in item.children"
                            :key="index" 
                            @click="clickMenu(it.url)" 
                            class="menu-item1">
                            {{it.title}}
                        </div>
                    </el-collapse-item> 
                    <div
                        v-else 
                        @click="clickMenu(item.url)" 
                        class="menu-item f-x-s-c">
                        {{item.title}}
                    </div>
                </div>
				
			</el-collapse>
            <div class="call f-y-c-c mt40" @click="callPhone">
                <div class="btn f-x-c-c">
                    <img class="call-img mr5" src="../image/common/menu_call.png"  />
                    <span class="num">{{phoneList[curCityInfo.value]}}</span>
                </div>
                <span class="text mt7">家装服务热线电话</span>
                
            </div>
            
		</div>
	</el-drawer>
</template>
<script>
import {mapMutations, mapState } from 'vuex'
export default {
	data() {
		return {
			showMenu: false,
			activeName: -1,
			menuList: [
				{
					title: '首页',
					url: '/',
				},
				{
					title: '装修产品',
					children: [
						{
							title: '整体家装',
							url: '/decoration/whole',
						},
						{
							title: '个性化定制整装',
							url: '/decoration/bardian',
						},
						{
							title: '局部改造',
							url: '/decoration/part',
						},
						{
							title: '软装搭配',
							url: '/decoration/collocation',
						},
					],
				},
				{
					title: '实景样板间',
					url: '/template',
				},
				{
					title: '工艺建材',
					children: [
						{
							title: '严选建材',
							url: '/build/strict',
						},
						{
							title: '创新工艺',
							url: '/build/innovate',
						},
					],
				},
				{
					title: '设计师',
					url: '/design',
				},
				{
					title: '装修案例',
					url: '/investor',
				},
				{
					title: '关于我们',
					children: [
						{
							title: '品牌简介',
							url: '/about/group',
						},
						{
							title: '企业蓝图',
							url: '/about/blueprint',
						},
						{
							title: '新闻动态',
							url: '/about/news',
						},
						{
							title: '门店地址',
							url: '/about/address',
						},
					],
				},
			],
            phoneList:{
                '00':'021-33888835',
                '310100':'021-33888835',
                '410100':'400-7878-000',
            }
		}
	},
    computed:{
        ...mapState(['curCityInfo'])
    },
    mounted(){
        this.setIndex()
    },
	methods: {
        setIndex(){
            if(this.curCityInfo.value=='410100'){
                this.menuList.splice(0,1,{
                    title: '首页',
                    url: '/zz',
                })
            }
            if(this.curCityInfo.value=='310100'){
                this.menuList.splice(0,1,{
                    title: '首页',
                    url: '/sh',
                })
            }
        },
        callPhone(){
            const isPhone = /mobile/i.test(navigator.userAgent)
            const phoneNum = this.phoneList[this.curCityInfo.value]
            if(isPhone)  window.location.href = `tel:${phoneNum}`;
        },
        clickMenu(path){
            const curPath = this.$route.path
            if(curPath==path) {
                this.$emit('changeOpenStatus')
                this.showMenu = false
            }
            else {
                this.$router.push({
                    path
                })
                this.showMenu = false
            }
            
        },
		closeMenu() {
			console.log('Aaaaaa')
			this.showMenu = false
		},
		change() {
			this.showMenu = !this.showMenu
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 900px) {
	.menu {
		padding: 0 12px 20px 12px;
		margin-top: 43px;
		/deep/.el-collapse-item {
            .el-collapse-item__content{
                padding-bottom: 0px !important;
            }
            .menu-item1{
                width: 100%;
                height: 45px;
                width: 350px;
                
                font-weight: 400;
                font-size: 18px;
                color: #210d3a;
                line-height: 45px;
                cursor: pointer;
            }
            .is-active {
                border-bottom: 1px solid #E5E5E5;
                // .box{
                //     border-bottom: 1px solid #E5E5E5;
                // }
            }
			.el-collapse-item__header {
				height: 45px;
               
				.box {
					width: 100%;
					height: 45px;
					padding-right: 15px;
					box-sizing: border-box;
					.title {
						
						font-weight: 600;
						font-size: 18px;
						color: #210d3a;
						line-height: 45px;
					}
                    
				}
                
				.el-collapse-item__arrow {
					display: none;
				}
			}
		}
        .menu-item{
            width: 100%;
			height: 45px;
            border-bottom: 1px solid #E5E5E5;
            
            font-weight: 600;
            font-size: 18px;
            color: #210d3a;
            line-height: 45px;
            cursor: pointer;
        }
        .call{
            .btn{
                // width: 260px;
                height: 55px;
                background: #FD8B2F;
                border-radius: 27px 27px 27px 28px;
                padding: 0 28px;
                // box-sizing: border-box;
                .call-img{
                    width: 27px;
                    height: 27px;
                }
                .num{
                    font-family: DIN;
                    font-weight: bold;
                    font-size: 28px;
                    color: #FFFFFF;
                }
            }
            .text{
                
                font-weight: 400;
                font-size: 15px;
                color: #A7A7A7;
            }
        }
	}
}
</style>
