// 样板间的列表页 product页面的所有数据
import instance from '@/utils/request.js'

// 样板间的列表也数据
export const getProduct = (current, size, designStyle, salesRoomNo, showHomePage) => {
  return instance({
    url: '/portal/show/room/page',
    method: 'get',
    params: {
      current: current,
      size: size,
      designStyle: designStyle,
      salesRoomNo: salesRoomNo,
      showHomePage: showHomePage,
    }
  })
}

// 样板间的列表也数据
export const getProductID = (id) => {
  return instance({
    url: '/portal/show/room/' + id,
    method: 'get'
  })
}

// 获取样板间的门店地址
export const getSalesRoomNo = (salesRoomNo) => {
  return instance({
    url: '/portal/sales/room/page',
    method: 'get',
    params: {
        size:100,
        current:1,
        state:1,
        salesRoomNo: salesRoomNo,
        orderBy:"sort asc"
    }
  })
}
