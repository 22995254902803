<template>
	<div class="floating-mob f-y-s-c">
		<div
            @click="toMsg"
			class="list-item"
		>
			<img class="item-img" src="../image/common/float_mob1.png" alt="" />
        </div>
        <transition name="el-fade-in" mode="in-out">
            <div
                v-if="showToTop"
                @click="toTop"
                class="list-item  mt10"
            >
                <img class="item-img" src="../image/common/float_mob2.png" alt="" />
            </div>
        </transition>
	</div>
    
</template>
<script>
export default {
	data() {
		return {
            windows:null,
            curIndex:-1,
            showToTop:false,
			list:[
                {
                    title:'在线咨询',
                    url:require('../image/common/float_mob1.png'),
                },
                {
                    title:'装修报价',
                    url:require('../image/common/float_mob2.png'),
                },
            ]
		}
	},
	async mounted() {
        window.addEventListener("scroll", this.scrollToTop);
	},
	methods: {
        toMsg(){
            window.open('https://uclient.yunque360.com/frame.html?company_id=cjapu0c1vpogu8', "_self");
        },
        toTop(){
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
        },
		showDialog() {
			this.show = true
		},
		closeDialog() {
			this.show = false
		},
        scrollToTop(){
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop >= 600) {
                this.showToTop = true;
            } else  {
                this.showToTop = false;
            }
        }
	},
    beforeDestroy(){
        window.removeEventListener("scroll",this.scrollToTop)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .floating-mob {
        z-index: 999;
		position: fixed;
		right: 13px;
		bottom: 90px;
        height: 80px;
        .list-item{
		    box-shadow: 0px 0px 10px 1px rgba(138, 137, 137, 0.3);
            border-radius: 50%;
            padding-bottom: 18px ;
            cursor: pointer;
            height: 35px;
            width: 35px;
            box-sizing: border-box;
            .item-img{
                width: 35px;
                height: 35px;
            }
        }
	}
}
@media screen and (min-width: 900px) {
    .floating-mob{
        display: none;
    }
}
</style>
