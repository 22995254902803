import instance from '@/utils/request.js'
import store from '@/store/index.js'
// 获取新闻的列表页
export const getNewsData = ({ current, size }) => {
    const params = {
		size,
        current,
        state:1,
		areaCode: store.state.curCityInfo.value,
	}
    if (store.state.curCityInfo.value == '00') {
        delete params.areaCode
        params.isHeadOffice = 1
    }
	return instance({
		url: '/portal/article/page',
		method: 'get',
		params
	})
}

// 获取对应id的新闻页
export const getNewsDetail = (id) => {
	return instance({
		url: '/portal/article/' + id,
		method: 'get',
	})
}
