<template>
    <el-dialog  :visible.sync="show" :show-close="false" :append-to-body="true">
        <div 
            class="visitor-form f-x-e-c"
            :style="`background:url(${formInfo[type].url})`"  
        >
            <div class="form-box f-y-c-c">
                <span class="title">{{ formInfo[type].title }}</span>
                <span class="desc">{{ formInfo[type].desc }}</span>
                <div class="box f-y-s-c">
                    <el-form
                        :inline="true" 
                        ref="ruleFormRef"
                        :model="visitorInfo"
                        :rules="rules"
                        class="cus-form"
                        >
                        <el-form-item   v-if="formInfo[type].showName">
                            <el-input
                                class="input"
                                placeholder="您的称呼"
                                v-model="visitorInfo.name"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item  v-if="formInfo[type].showMobile" prop="mobile">
                            <el-input
                                
                                class="input"
                                placeholder="您的联系方式"
                                v-model="visitorInfo.mobile"
                                inputmode="numeric"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item   v-if="formInfo[type].showArea" prop="houseArea">
                            <el-input
                                
                                class="input"
                                placeholder="您家的房屋面积"
                                v-model="visitorInfo.houseArea"
                                inputmode="numeric"
                            >
                                <div slot="suffix" class="m2">m²</div>
                            </el-input>
                        </el-form-item>
                        <el-form-item    v-if="formInfo[type].showHouseType">
                            <el-input
                                class="input"
                                placeholder="您的房屋户型"
                                v-model="visitorInfo.houseType"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item   v-if="formInfo[type].showCity">
                            <el-cascader
                                class="input city"
                                placeholder="您所在城市门店"
                                size="default"
                                :options="cityStoreList"
                                v-model="city"
                            >
                            </el-cascader>
                        </el-form-item>
                    </el-form>
                </div>
                <div @click="submitForm" class="btn f-x-c-c">提交留言</div>
                <!-- <span class="tip">为了你的权益，你的隐私将被严格保密</span> -->
            </div>
            <div @click="closeDialog" class="close-btn">
                <img class="img" src="../image/common/dialog_close.png" alt="">
            </div>
            
        </div>

        <div 
            class="visitor-form1 f-y-s-c"
        >
            <div 
                class="form-top f-y-c-c"
                :style="`background:no-repeat center/cover url(${formInfo[type].url})`"      
            >
                <div class="mask"></div>
                <span class="title">{{ formInfo[type].title }}</span>
                <span class="desc">{{ formInfo[type].desc }}</span>
            </div>
            <div class="form-box f-y-c-c">
                
                <div class="box f-y-s-c">

                    <el-input
                        v-if="formInfo[type].showName"
                        class="input"
                        placeholder="您的称呼"
                        v-model="visitorInfo.name"
                    >
                    </el-input>
                    <el-input
                        v-if="formInfo[type].showMobile"
                        class="input"
                        placeholder="您的联系方式"
                        v-model="visitorInfo.mobile"
                        inputmode="numeric"
                    >
                    </el-input>
                    <el-input
                        v-if="formInfo[type].showArea"
                        class="input"
                        placeholder="您家的房屋面积"
                        v-model="visitorInfo.houseArea"
                        inputmode="numeric"
                    >
                        <div slot="suffix" class="m2">m²</div>
                    </el-input>
                    <el-input
                        v-if="formInfo[type].showHouseType"
                        class="input"
                        placeholder="您的房屋户型"
                        v-model="visitorInfo.houseType"
                    >
                    </el-input>
                    <!-- <el-select v-model="visitorInfo.salesRoomNo" placeholder="您所在城市门店">
                        <el-option
                            v-for="item in storeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                    </el-select> -->

                    <el-cascader
                        v-if="formInfo[type].showCity"
                        class="input city"
                        placeholder="您所在城市门店"
                        size="default"
                        :options="cityStoreList"
                        v-model="city"
                    >
                    </el-cascader>
                </div>
                <div @click="submitForm" class="btn f-x-c-c">提交留言</div>
                <!-- <span class="tip">为了你的权益，你的隐私将被严格保密</span> -->
            </div>
            <div @click="closeDialog" class="close-btn">
                <img class="img" src="../image/common/dialog_close.png" alt="">
            </div>
            
        </div>
        <MessageForm ref="refMessage" :list="errList"></MessageForm>
    </el-dialog>
	
</template>
<script>
import { postmobile } from '@/api/views/name.js'
import {useCityInfo} from '@/utils/frequent.js'

export default {
	props: {
		adUnitCode: {
			type: String,
            default:"XSdw"
		},
	},
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback('请输入正确的电话号码');
            }else{
                callback();
            }
            
        };
		return {
            type:'price',
            show:false,
            city:[],
            cityStoreList:[],
			visitorInfo: {
				houseArea: '',
				mobile: '', //电话
				name: '',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: '', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
                houseType:'',
                salesRoomNo:''
			},
            rules:{
                // houseArea: [
                //     { required: true, message: ' ', trigger: 'blur' },
                // ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            errList:[],
            formInfo:{
                price:{
                    title:'装修报价',
                    desc:'免费获取您家装修花多少钱？',
                    btnText:'立即计算报价',
                    showMobile:true,
                    showArea:true,
                    showHouseType:true,
                    url:require('../image/common/dialog_bg1.jpg')
                },
                plan:{
                    title:'设计方案',
                    desc:'免费获取装修设计方案',
                    btnText:'提交留言',
                    showName:true,
                    showArea:true,
                    showMobile:true,
                    url:require('../image/common/dialog_bg2.jpg')
                },
                store:{
                    title:'预约到店',
                    desc:'免费参观建材+工艺+样板间',
                    btnText:'免费获取报价',
                    showName:true,
                    showMobile:true,
                    showCity:true,
                    url:require('../image/common/dialog_bg3.jpg')
                },
                design:{
                    title:'预约设计师',
                    desc:'上门量房 + 户型设计',
                    btnText:'免费获取报价',
                    showName:true,
                    showMobile:true,
                    url:require('../image/common/dialog_bg4.jpg')
                },
            },
            timer:""
		}
	},
	async mounted() {
		this.setAccessInfo()
        this.cityStoreList = await useCityInfo()
        if(this.curCityInfo.value!='00') this.setCity()
	},
	methods: {
        showDialog(type){
            this.type = type
            this.show = true
        },
        closeDialog(){
            this.show = false
        },
        
		async setAccessInfo() {
            
            // const curCity = this.

			this.visitorInfo.adUnitCode = this.adUnitCode

			this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
			this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
			this.visitorInfo.reqUrl = window.location.href

            this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
            this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
            this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
            this.visitorInfo.projectName =  this.$Cookie.get('projectName')
            this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
		},
        async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    if(valid){
                        if(this.city.length){
                            this.visitorInfo.cityCode = this.city[0]
                            this.visitorInfo.salesRoomNo = this.city[1]
                        }

                        const res = await postmobile(this.visitorInfo)
                        this.closeDialog()
                        this.$refs.refMessage.showDialog('sucess')
                        this.city=[]
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        this.visitorInfo.houseType = ''

                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res

                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
		// async submit() {
		// 	try {
		// 		if (
		// 			!this.visitorInfo.mobile.match(
		// 				/^[1][3,4,5,6.7,8,9][0-9]{9}$/
		// 			)
		// 		) {
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message: '请输入正确的手机号',
		// 				type: 'warning',
		// 			})
		// 			// this.visitorInfo.mobile = ''
		// 		} else {
        //             if(this.city.length){
        //                 this.visitorInfo.cityCode = this.city[0]
		// 			        this.visitorInfo.salesRoomNo = this.city[1]
        //             }

		// 			const res = await postmobile(this.visitorInfo)
        //             this.city=[]
		// 			this.visitorInfo.mobile = ''
		// 			this.visitorInfo.name = ''
		// 			this.visitorInfo.provinceCode = ''
		// 			this.visitorInfo.cityCode = ''
		// 			this.visitorInfo.houseArea = ''
		// 			this.visitorInfo.houseType = ''
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message:
		// 					'提交成功！稍后家装顾问与您联系，请注意021开头电话',
		// 				duration: 2000,
		// 				offset: 120,
		// 			})
		// 		}
		// 	} catch (err) {
		// 		console.log(err)
		// 	}
		// },
	},
    beforeDestroy(){
        clearTimeout(this.timer)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    /deep/ .el-dialog{
        border-radius: 4px !important;
        margin: 15vh auto !important;
        width: 300px;
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body{
            padding: 0 !important;
        }
    }
    .visitor-form{
        display: none;
    }
	.visitor-form1 {
		width: 300px;
		// height: 350px;
        background: #FFFFFF;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        background-size:100% 100% !important;
        background-repeat: no-repeat !important;
        .form-top{
            height: 110px;
            width: 100%;
            position: relative;
            border-radius: 4px 4px 0 0;
            .mask{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 4px 4px 0 0;
                background: rgba(0, 0, 0, 0.3);
            }
            .title{
                z-index: 9;
                
                font-weight: bold;
                font-size: 24px;
                color: #ffffff;
                line-height: 24px; 
            }
            .desc{
                z-index: 9;
                
                font-weight: 400;
                font-size: 18px;
                margin-top: 5px;
                color: #ffffff;
            }
        }
        .close-btn{
            cursor: pointer;
            position: absolute;
            right: -12px;
            top: -12px;
            width: 24px;
            height:24px;
            // background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.06);
            border-radius: 50%;
            .img{
                width: 100%;
                height: 100%;
            }
        }
        .form-box{
            width: 100%;
            // height: 200px;
            background: #FFFFFF;
            border-radius: 4px;
            opacity: 0.96;
            padding:20px 10px;
            background-repeat: no-repeat;
            box-sizing: border-box;
           
            .box{
                width: 100%;
                .input{
                    width: 100%;
                    margin-bottom: 10px;
                    /deep/ .el-input{
                        width: 100%;
                        height:40px;
                        
                        .el-input__inner{
                            width: 100%;
                            height:40px;
                        }
                    }
                    /deep/ .el-input__inner{
                        width: 100%;
                        height:40px;
                        font-size: 12px !important;
                    }
                    
                    
                    /deep/.el-input__suffix{
                        right: 10px;
                        .m2{
                            height:40px;
                            line-height:40px;
                            font-size: 12px;
                        }
                    }
                    /deep/.el-icon-arrow-down{
                        font-size: 12px !important;

                    }
                }
                .el-cascader{
                    /deep/.el-input__suffix{
                        right: 13px !important;
                    }
                }
                
            }
            .btn{
                // width: 410px;
                width: 100%;
                height:40px;
                background: #F6900C;
                border-radius: 2px;
                
                font-weight: bold;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 16px;
                cursor: pointer;
            }
        }
		
	}

}
@media screen and (min-width: 900px) {
    /deep/ .el-dialog{
        border-radius: 8px !important;
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body{
            padding: 0 !important;
        }
    }
    .visitor-form1{
        display: none;
    }
	.visitor-form {
		width: 1000px;
		height: 600px;
        background: #FFFFFF;
        border-radius: 8px;
        box-sizing: border-box;
        position: relative;
        background-size:100% 100% !important;
        background-repeat: no-repeat !important;
        .close-btn{
            cursor: pointer;
            position: absolute;
            right: -25px;
            top: -25px;
            width: 50px;
            height:50px;
            // background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.06);
            border-radius: 50%;
            .img{
                width: 100%;
                height: 100%;
            }
        }
        .form-box{
            width: 480px;
            height: 600px;
            background: #FFFFFF;
            border-radius: 0px 8px 8px 0px;
            opacity: 0.96;
            padding: 30px;
            background-repeat: no-repeat;
            box-sizing: border-box;
            .title{
                
                font-weight: bold;
                font-size: 54px;
                color: #753CBE;
                line-height: 54px; 
            }
            .desc{
                
                font-weight: 400;
                font-size: 32px;
                color: #666666;
                margin-top: 18px;
            }
            .box{
                margin-top: 32px;
                width: 100%;
                .cus-form{
                    .el-form-item{
                        width: 100%;
                        /deep/.el-form-item__content{
                            width: 100%;
                        }
                        
                    }
                }
                .input{
                    width: 100%;
                    margin-bottom: 20px;
                    /deep/ .el-input{
                        width: 100%;
                        height: 80px;
                        
                        .el-input__inner{
                            width: 100%;
                            height: 80px;
                        }
                    }
                    /deep/ .el-input__inner{
                        width: 100%;
                        height: 80px;
                        font-size: 24px !important;
                    }
                    
                    
                    /deep/.el-input__suffix{
                        right: 20px;
                        .m2{
                            height: 80px;
                            line-height: 80px;
                            font-size: 24px;
                        }
                    }
                    /deep/.el-icon-arrow-down{
                        font-size: 24px !important;

                    }
                }
                .el-cascader{
                    /deep/.el-input__suffix{
                        right: 27px !important;
                    }
                }
                
            }
            .btn{
                // width: 410px;
                width: 100%;
                height: 80px;
                background: #F6900C;
                border-radius: 4px;
                
                font-weight: bold;
                font-size: 36px;
                color: #FFFFFF;
                line-height: 36px;
                cursor: pointer;
            }
            .tip{
                
                font-weight: 300;
                font-size: 18px;
                color: #140626;
                line-height: 18px;
                margin-top: 15px;
            }
        }
		
	}
}
</style>
