import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
//
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: () => import('../views/home.vue'),
		meta: {
			title: '千家美4',
			content: {
				keywords: '',
				description: '666',
			},
		},
	},
    {
		path: '/sh',
		component: () => import('../views/home-sh.vue'),
		meta: {
			title: '千家美',
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    {
		path: '/zz',
		component: () => import('../views/home-zz.vue'),
		meta: {
			title: '千家美',
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    // 装修产品 / 整体家装
    {
        path: '/decoration/whole',
        // component: () => import('../views/Finish.vue'),
        component: () => import('../views/decorationWhole.vue'),
        meta: {
            content: {
                keywords: '',
                description: ''
            }
        }
    },
    // 个性化定制
	{
		path: '/decoration/bardian',
		component: () => import('../views/decorationBardian.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    // 局改换新
	{
		path: '/decoration/part',
		// component: () => import('../views/Decoration.vue'),
		component: () => import('../views/decorationPart.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    // 软装搭配
	{
		path: '/decoration/collocation',
		// component: () => import('../views/Solution.vue'),
		component: () => import('../views/decorationCollocation.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},

    // 实景样板间
	{
		path: '/template',
		component: () => import('../views/template.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    {
		path: '/template/detail',
		component: () => import('../views/template-detail.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},

	// 严选建材
	{
		path: '/build/strict',
		component: () => import('../views/buildStrict.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    //创新工艺
    {
		path: '/build/innovate',
		component: () => import('../views/buildInnovate.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},

    // 设计团队
	{
		path: '/design',
		component: () => import('../views/design.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    // 设计师详情页
	{
		path: '/design/detail',
		component: () => import('../views/design-detail.vue'),
	},

    // 装修案例
	{
		path: '/investor',
		component: () => import('../views/investor.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    // 装修案例详情页
    {
        path: '/investor/detail',
        component: () => import('../views/investor-detail.vue'),
    },
    // 关于我们/门店地址
	{
		path: '/about/address',
		component: () => import('../views/aboutAddress.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    // 品牌简介
	{
		path: '/about/group',
		component: () => import('../views/about-group.vue'),
		meta: {
			content: {
				keywords: '',
				description: '',
			},
		},
	},
    // 新闻动态
    {
        path: '/about/news',
        component: () => import('../views/about-news.vue'),
        meta: {
            content: {
                keywords: '',
                description: '',
            },
        },
    },
     // 新闻动态详情
     {
        path: '/about/news-detail',
        component: () => import('../views/about-news-detail.vue'),
        meta: {
            content: {
                keywords: '',
                description: '',
            },
        },
    },
    // 企业蓝图
    {
        path: '/about/blueprint',
        component: () => import('../views/about-blueprint.vue'),
        meta: {
            content: {
                keywords: '',
                description: '',
            },
        },
    },
    // 装修报价
    {
        path: '/quotation',
        component: () => import('../views/quotation.vue'),
        meta: {
            content: {
                keywords: '',
                description: '',
            },
        },
    },
    {
        path: '/ad/active',
        component: () => import('../views/active/index.vue')
    },
    {
        path: '/ad/active-mob',
        component: () => import('../views/active-mob/index.vue')
    }
    
]

const router = new VueRouter({
	routes,
	mode: 'hash',
	//   mode: 'history'
	// base: '/mj/'
})

export default router
