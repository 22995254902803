<template>
	<div id="app">
		<router-view :key="key"></router-view>
	</div>
</template>

<script>
var _hmt = _hmt || []
;(function () {
	var hm = document.createElement('script')
	hm.src = 'https://hm.baidu.com/hm.js?5187ab9825d3ac6cc7da938760583446'
	var s = document.getElementsByTagName('script')[0]
	s.parentNode.insertBefore(hm, s)
})()
</script>

<script>
import { getInterviewId, getVisitorId } from '@/api/behavior.js'
import { mapMutations, mapState } from 'vuex'
import config from '@/config/index.js'
import { getmedia } from '@/api/advertising/advertising.js'
import { getactivity, getactivityId } from '@/api/views/name.js'

// // // 这里是53客服的直接打开就可以用
// (function (y, un, q, u, e) {
//   window[un] =
//     window[un] ||
//     function (y) {
//       window[un].company_id = window[un].company_id || y;
//     };
//   u = document.createElement("script");
//   u.src = q + "?v=" + new Date().getUTCDate();
//   u.async = false;
//   e = document.getElementsByTagName("script")[0];
//   e.parentNode.insertBefore(u, e);
// })(window, "_YUNQUE", "//dist.yunque360.com/bundle.js"); /*  */
// // eslint-disable-next-line no-undef
// _YUNQUE("cjapu0c1vpogu8");
// // _YUNQUE.hideDialog();

export default {
	created() {
		// const path = this.$route.path

		// 拿到媒体
		// getmedia().then((data) => {
		// })

		// 存储访客id
		if (!this.$Cookie.get('MJ0INTERVIEWID')) {
			getInterviewId().then((data) => {
				this.$Cookie.set('MJ0INTERVIEWID', data.data, {
					expires: 180,
				})
			})
		}
		// 存储访次id
		if (!this.$Cookie.get('MJ0VISITORID')) {
			getVisitorId().then((data) => {
				this.$Cookie.set('MJ0VISITORID', data.data, {
					expires: 1,
				})
			})
		}
		// 获取当前用户的ip并存储到cookie
		// eslint-disable-next-line no-undef
		var Ip = returnCitySN.cip
		this.$Cookie.set('ip', Ip, {
			expires: 9999,
		})
		// 获取当前用户的id并存储到cookie
		// eslint-disable-next-line no-undef
		var Id = returnCitySN.cid
		this.$Cookie.set('MJ0CITYCODE', Id, {
			expires: 9999,
		})
		// 获取当前用户的地址并存储到cookie
		// eslint-disable-next-line no-undef
		var cname = returnCitySN.cname
		this.$Cookie.set('Name', cname, {
			expires: 9999,
		})
		// 存储会员
		this.$Cookie.set('isMember', 0, {
			expires: 9999,
		})

		// 获取操作系统
		// eslint-disable-next-line camelcase
		const user_agent = navigator.userAgent
		var UA = require('ua-device')
		// eslint-disable-next-line camelcase
		var input = user_agent
		var output = new UA(input)

		// 添加操作系统和版本号
		this.$Cookie.set(
			'operatingSystem',
			output.os.name + '/' + output.os.version.alias,
			{
				expires: 9999,
			}
		)
		// 添加浏览器类型
		this.$Cookie.set('browserType', output.browser.name, {
			expires: 9999,
		})
		// 添加浏览器版本
		this.$Cookie.set('browserVersion', output.browser.version.original, {
			expires: 9999,
		})
		// 浏览器支持的编码格式
		this.$Cookie.set('browserEncoding', document.charset, {
			expires: 9999,
		})
		// 浏览器使用的语言
		this.$Cookie.set(
			'browserLanguage',
			(navigator.language || navigator.browserLanguage).toLowerCase(),
			{
				expires: 9999,
			}
		)
		// 客户屏幕的分辨率
		this.$Cookie.set(
			'screenResolution',
			window.screen.width + 'x' + window.screen.height,
			{
				expires: 9999,
			}
		)

		// 获取当前客户端的时间
		var date = new Date()
		var time =
			date.getFullYear() +
			'-' +
			(date.getMonth() + 1) +
			'-' +
			date.getDate()
		var time1 =
			date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
		this.$Cookie.set('clientTime', time + ' ' + time1, {
			expires: 9999,
		})

		this.setCurCityData()
		this.getmediaData()
	},
	computed: {
		key() {
			return this.$route.fullPath
		},
	},
	mounted() {},
	methods: {
		...mapMutations(['setCurCity']),
		setCurCityData() {
			const hostname = window.location.origin
			console.log('hostname', hostname)
			const res = config.cityList.find(
				(item) => item.hostname == hostname
			)
			if (res) {
				this.setCurCity({
					value: res.cityCode,
					label: res.name,
				})
			} else {
				this.setCurCity({
					value: '00',
					label: '全国',
				})
			}
		},
		getmediaData() {
			getmedia().then((data) => {
				console.log('Data', data)
				let re = ''

				data.data.records.forEach((obj) => {
					if (obj.sourceCode === 'website') {
						if (obj.partnersCode == 'P224') {
							re = obj.partnersCode
							//   this.mediaSource = obj.partnersCode;
							//   this.mediaSourceName = obj.partnersName;
							// 存储营销活动代码
							if (!this.$Cookie.get('mediaName')) {
								this.$Cookie.set(
									'mediaName',
									obj.partnersName,
									{
										expires: 180,
									}
								)
							}
						}
					}
				})
				// 请求营销计划的官网分页
				getactivity(re).then((data) => {
                    console.log('营销计划数据', data)
					getactivityId(data.data.id).then((data) => {
						console.log('获取活动数据', data)
						// this.activityCode = data.data.activityCode;
						// this.planCode = data.data.planCode;
						// this.adsPlanName = data.data.planName;

						// 存储营销活动代码
						if (!this.$Cookie.get('activityCode')) {
							this.$Cookie.set(
								'activityCode',
								data.data.activityCode,
								{
									expires: 180,
								}
							)
						}

						// 存储营销计划Code
						if (!this.$Cookie.get('projectCode')) {
							this.$Cookie.set(
								'projectCode',
								data.data.planCode,
								{
									expires: 180,
								}
							)
						}

						// 存储媒体代码
						if (!this.$Cookie.get('mediaCode')) {
							this.$Cookie.set(
								'mediaCode',
								data.data.partnersList[0].partnersCode,
								{
									expires: 180,
								}
							)
						}

						// 存储营销活动代码
						if (!this.$Cookie.get('projectName')) {
							this.$Cookie.set(
								'projectName',
								data.data.planName,
								{
									expires: 180,
								}
							)
						}

						// 存储渠道代码
						if (!this.$Cookie.get('channelCode')) {
							this.$Cookie.set('channelCode', 'website', {
								expires: 180,
							})
						}
					})
				})
			})
		},
	},
}
</script>
<style lang="scss">
@import './assets/styles/common.scss';
@import './assets/styles/element.scss';
// @import './assets/fonts/font.css'
</style>
<style lang="less">
body {
	margin: 0px;
}
* {
	margin: 0;
	padding: 0;
	border: none;
	vertical-align: baseline;
	list-style: none;
	border-collapse: collapse;
	border-spacing: 0;
}
#yunque_btn-holder {
	display: none;
}
</style>
