// // home页面的所有数据
import instance from '@/utils/request.js'
import store from '@/store/index.js'
// 获取装修案例列表
export const getCaseData = ({
    size,
	current,
	designerId,
    salesRoomNo,
	houseArea,
	houseType,
	designStyle,
	showHomePage,
    isRecommend,
    areaCode,
    state=1
}
) => {
    const params = {
		size,
        current,
        designerId,
        designStyle,
        salesRoomNo,
        houseArea,
        houseType,
        showHomePage,
        isRecommend,
        state,
		areaCode: areaCode || store.state.curCityInfo.value,
	}
	if (store.state.curCityInfo.value == '00') {
        params.isHeadOffice = 1
    }
    if(params.areaCode=='00'){
        delete params.areaCode
    }
	return instance({
		url: '/portal/trim/case/page',
		params
	})
}
// 获取案例详情
export const getCaseDetail = (id) => {
    return instance({
      url: '/portal/trim/case/' + id
    })
  }
