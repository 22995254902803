<template>
	<div v-if="total>pageSize" class="page f-x-c-c">
        <div @click="prePage" class="btn f-x-c-c">上一页</div>
        <el-pagination
            background
            :page-size="pageSize"
            :current-page="currentPage"
            layout="pager"
            :total="total"
            @current-change="changeCurrent"
            >
        </el-pagination>
        <div @click="nextPage" class="btn f-x-c-c">下一页</div>
    </div>
</template>

<script>
export default {
    props:{
        pageSize:{
            type:Number,
            default:10,
        },
        currentPage:{
            type:Number,
            default:1,
        },
        total:{
            type:Number,
            default:0,
        },
        
    },
	data() {
		return {
			
		}
	},
	mounted() {
	},
	methods: {
        changeCurrent(data){
            this.$emit('current-change',data)
        },
        prePage(){
            if(this.currentPage!=1){
                this.$emit('current-change',this.currentPage-1)
            }
        },
        nextPage(){
            if(this.currentPage<((this.total/this.pageSize).toFixed(2))){
                this.$emit('current-change',this.currentPage+1)
            }
        },
	},
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .page{
        width: 100%;
        .btn{
            width: 60px;
            height: 30px;
            border: 1px solid #E3E3E3;   
            box-sizing: border-box;
            color: #333;
            font-size: 12px;
            border-radius: 3px;
            cursor: pointer;
        }
        /deep/ .el-pager li{
            height: 30px;
            width: 30px;
            line-height: 30px;
            font-size: 10px;
            border-radius: 3px !important;
            border: 1px solid #DEDEDE;
            box-sizing: border-box;
        }
        /deep/ .el-pager li:hover{
            background-color: #713AB7 !important;
            color: #fff !important;
        }
        /deep/ .el-pager  .active{
            background-color: #713AB7 !important;
        }
    }
}
@media screen and (min-width: 900px) {
	.page{
        width: 100%;
        .btn{
            width: 110px;
            height: 60px;
            border: 1px solid #E3E3E3;   
            box-sizing: border-box;
            color: #333;
            font-size: 16px;
            border-radius: 6px;
            cursor: pointer;
        }
        /deep/ .el-pager li{
            height: 60px;
            width: 60px;
            line-height: 60px;
            font-size: 20px;
            border-radius: 6px !important;
            border: 1px solid #DEDEDE;
            box-sizing: border-box;
        }
        /deep/ .el-pager li:hover{
            background-color: #713AB7 !important;
            color: #fff !important;
        }
        /deep/ .el-pager  .active{
            background-color: #713AB7 !important;
        }
    }
}
</style>
