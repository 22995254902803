import axios from 'axios'
import config from '@/config/index.js'
axios.defaults.headers['content-type'] = 'application/json'
axios.defaults.headers['TENANT-ID'] = config.tenantId// 租户ID
axios.defaults.headers['Authorization'] = 'Basic cWptLXBvcnRhbDpxaWFuamlhbWVpLXBvcnRhbC1zZXJ2ZXI='// token // 租户ID

// 创建一个axios实例对象
const instance = axios.create({
  baseURL: '/'
  // timeout: 6000
})

// 添加响应拦截器
instance.interceptors.response.use(function (data) {
  // 对响应数据做点什么
  return data.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})
export default instance

// 请求拦截器
axios.interceptors.request.use(function (config) {
  // 发送请求的相关逻辑
  // config:对象  与 axios.defaults 相当
  // 借助config配置token
  let userinfo = window.sessionStorage.getItem('userinfo')
  // 判断token存在再做配置
  if (userinfo) {
    let token = JSON.parse(userinfo).token
    // 注意：token前边有 'Bearer ' 的信息前缀,API接口需求，Bearer后边有空格
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})
