<template>
	<div class="tabbar f-y-b-c">
        <div class="box"></div>
        <div class="list-box f-x-b-c">
            <div
                v-for="(item,index) in list"
                :key="index"
                @click="handleClick(index)"
                class="list-item f-x-b-c"
            >
                <img class="item-img" :src="item.url" alt="" />
                <span class="text1">{{item.title}}</span>
            </div>
        </div>
	</div>
    
</template>
<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
            windows:null,
            curIndex:-1,
            showToTop:false,
            phoneList:{
                '00':'021-33888835',
                '310100':'021-33888835',
                '410100':'400-7878-000',
            },
			list:[
                {
                    title:'一键拨号',
                    url:require('../image/common/tabbar_1.png'),
                },
                {
                    title:'在线咨询',
                    url:require('../image/common/tabbar_2.png'),
                },
                {
                    title:'装修报价',
                    url:require('../image/common/tabbar_3.png'),
                },
            ]
		}
	},
    computed:{
        ...mapState(['curCityInfo'])
    },
	methods: {
        handleClick(index){
            switch(index){
                case 0:
                    window.location.href = `tel:${this.phoneList[this.curCityInfo.value]}`;
                    break
                case 1:
                    window.open('https://uclient.yunque360.com/frame.html?company_id=cjapu0c1vpogu8', "_self");
                    break
                case 2:
                    this.$router.push('/quotation')
                    break
            }
        },
       
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .tabbar {
        z-index: 999;
        height: 60px;
        width: 100%;
        .box{
            height: 60px;
            width: 100%;
        }
        .list-box{
            z-index: 999;
            position: fixed;
            right: 0;
            bottom: 0;
            height: 60px;
            width: 100%;

            background: #FFFFFF;
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.23);
            .list-item{
                padding: 15px;
                border-right: 1px solid #e4e4e4;
                height: 100%;
                // width: 35px;
                box-sizing: border-box;
                .item-img{
                    width: 22px;
                    height: 22px;
                }
                .text1{
                    
                    font-weight: 500;
                    font-size: 16px;
                    color: #2B1546;
                    line-height: 16px;
                    margin-left: 5px;
                    font-weight: 600;
                }
            }
        }
        
	}
}
@media screen and (min-width: 900px) {
    .tabbar{
        display: none;
    }
}
</style>
