<template>
    <el-dialog class="doalog-box"  :visible.sync="show" :show-close="false" :append-to-body="true">
        <div class="content f-y-c-c">
            <img @click="show=false" class="img-close" src="../image/common/dialog_close.png" alt="">
            <img class="img" src="../image/common/message.png" alt="">
            
            <div class="box f-y-c-c" v-if="type=='sucess'">
                <span class="text1">提交成功</span>
                <span class="text2">我们将在12小时内与你联</span>
                <span class="text3">系，请保持电话畅通！</span>
            </div>
            <div class="box2 f-y-c-c" v-if="type=='error'">
                <span class="text1" v-for="(item,index) in list" :key="index">{{item}}</span>
            </div>
        </div>
        
        
    </el-dialog>
	
</template>
<script>
import { postmobile } from '@/api/views/name.js'
import {useCityInfo} from '@/utils/frequent.js'

export default {
	props: {
        list:{
            type: Array,
			default: [],    
        }
	},
	data() {
		return {
            type:'sucess',
            show:false,
		}
	},
	async mounted() {},
	methods: {
        showDialog(type){
            this.type = type
            this.show = true
        },
        closeDialog(){
            this.show = false
        },
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    /deep/ .el-dialog{
        width:320px !important;
        // height: 150px !important;
        border-radius: 8px !important;
        padding: 15px !important;
        box-sizing: border-box !important;
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body{
            
            padding: 0 !important;
        }
    }
    .doalog-box{
        .content{
            
            
            height: 100%;
            width: 100%;
            .img{
                width: 50px;
                height: 50px;
            }
            .img-close{
                width: 30px;
                height: 30px;
                position: absolute;
                right: -15px;
                top: -15px;
                cursor: pointer;
            }
            .box{
                margin-top: 8px;
                .text1{
                    font-family: Source Han Sans SC;
                    font-weight: bold;
                    font-size: 18px;
                    color: #20093D;
                    line-height: 20px;
                }
                .text2{
                    margin-top: 5px;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #555555;
                    line-height: 20px;
                }
                .text3{
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #555555;
                    line-height: 20px;
                }
            }
            .box2{
                margin-top: 18px;
                .text1{
                    font-family: Source Han Sans SC;
                    font-weight: 300;
                    font-size: 14px;
                    color: #E90D0D;
                    line-height: 20px;
                }
            }
        }
    }
}
@media screen and (min-width: 900px) {
    /deep/ .el-dialog{
        width:370px !important;
        // height: 320px !important;
        border-radius: 8px !important;
        padding: 25px !important;
        box-sizing: border-box !important;
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body{
            
            padding: 0 !important;
        }
    }
    .doalog-box{
        .content{
            
            
            height: 100%;
            width: 100%;
            .img{
                width: 100px;
                height: 100px;
            }
            .img-close{
                width: 50px;
                height: 50px;
                position: absolute;
                right: -25px;
                top: -25px;
                cursor: pointer;
            }
            .box{
                margin-top: 27px;
                .text1{
                    font-family: Source Han Sans SC;
                    font-weight: bold;
                    font-size: 36px;
                    color: #20093D;
                    line-height: 40px;
                }
                .text2{
                    margin-top: 24px;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    font-size: 28px;
                    color: #555555;
                    line-height: 40px;
                }
                .text3{
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    font-size: 28px;
                    color: #555555;
                    line-height: 40px;
                }
            }
            .box2{
                margin-top: 36px;
                .text1{
                    font-family: Source Han Sans SC;
                    font-weight: 300;
                    font-size: 28px;
                    color: #E90D0D;
                    line-height: 40px;
                }
            }
        }
        
    }
   
  
}
</style>
