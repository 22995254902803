<template>
	<div class="floating f-y-c-c" :class="{'isTop':isShowTop}">
        <transition-group name="el-fade-in" style="width:100%">
            <div
                v-for="(item, index) in listData"
                :key="index"
                @click="handleClick(index)"
                @mouseenter="curIndex=index"
                @mouseleave="curIndex=-1"
                class="list-item f-y-s-c"
            >
                <div v-if="index!=0" class="line"></div>     
                <img v-if="curIndex == index" class="item-img" :src="item.activeUrl" alt="" />
                <img v-else class="item-img" :src="item.url" alt="" />
                <span class="text1">{{ item.title }}</span>
            </div>
        </transition-group>

        <DialogForm ref="refDialogForm" adUnitCode="vbnm"></DialogForm>
	</div>
    
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: 'price', //price  plan  store  design
		},
	},
	data() {
		return {
            isShowTop:false,
            windows:null,
            curIndex:-1,
            showToTop:false,
            listData:[],
			list:[
                {
                    title:'在线咨询',
                    activeUrl:require('../image/common/floating_active1.png'),
                    url:require('../image/common/floating_1.png'),
                },
                {
                    title:'装修报价',
                    activeUrl:require('../image/common/floating_active2.png'),
                    url:require('../image/common/floating_2.png'),
                },
                {
                    title:'设计方案',
                    activeUrl:require('../image/common/floating_active3.png'),
                    url:require('../image/common/floating_3.png'),
                },
                {
                    title:'预约到店',
                    activeUrl:require('../image/common/floating_active4.png'),
                    url:require('../image/common/floating_4.png'),
                },
                {
                    title:'返回顶部',
                    activeUrl:require('../image/common/floating_active5.png'),
                    url:require('../image/common/floating_5.png'),
                },
            ],
            list1:[
                {
                    title:'在线咨询',
                    activeUrl:require('../image/common/floating_active1.png'),
                    url:require('../image/common/floating_1.png'),
                },
                {
                    title:'装修报价',
                    activeUrl:require('../image/common/floating_active2.png'),
                    url:require('../image/common/floating_2.png'),
                },
                {
                    title:'设计方案',
                    activeUrl:require('../image/common/floating_active3.png'),
                    url:require('../image/common/floating_3.png'),
                },
                {
                    title:'预约到店',
                    activeUrl:require('../image/common/floating_active4.png'),
                    url:require('../image/common/floating_4.png'),
                },
            ]
		}
	},
	async mounted() {
        window.addEventListener("scroll", this.scrollToTop);
        this.listData = this.list1
	},
	methods: {
        scrollToTop(){
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop >= 1000) {
                this.listData = this.list;
                this.isShowTop = true
            } else  {
                this.listData = this.list1;
                this.isShowTop = true
            }
        },
        handleClick(index){
            switch(index){
                case 0:
                    if(window._YUNQUE) _YUNQUE.openDialog()
                    else{
                        (function (y, un, q, u, e) {
                            window[un] =
                                window[un] ||
                                function (y) {
                                    window[un].company_id = window[un].company_id || y;
                                    window[un].isOpenNewWindow = true;
                                    window[un].clientDomain = "yq-mina-cjapu0c1vpogu8";
                                };
                                u = document.createElement("script");
                                u.src = q + "?v=" + new Date().getUTCDate();
                                u.async = true;
                                e = document.getElementsByTagName("script")[0];
                                e.parentNode.insertBefore(u, e);
                        })(window, "_YUNQUE", "//dist.yunque360.com/bundle.js");
                        _YUNQUE("cjapu0c1vpogu8"); 
                    }
                    break
                case 1:
                this.$refs.refDialogForm.showDialog('price')
                    break
                case 2:
                    this.$refs.refDialogForm.showDialog('plan')
                    break
                case 3:
                    this.$refs.refDialogForm.showDialog('store')
                    break
                case 4:
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth' // 平滑滚动
                    });
                    break
            }
        },
		showDialog() {
			this.show = true
		},
		closeDialog() {
			this.show = false
		},
	},
    beforeDestroy(){
        window.removeEventListener("scroll",this.scrollToTop)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .floating{
        display: none;
    }
}
@media screen and (min-width: 900px) {
    .isTop{
        bottom: calc((100vh - 450px)/2);
    }
	.floating {
        z-index: 999;
		position: fixed;
		right: 0;
		bottom: calc((100vh - 360px)/2);
		width: 90px;
		// height: 604px;
		background: #ffffff;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.06);
		border-radius: 8px 0px 0px 8px;
        overflow: hidden;

        .list-item:hover{
            background: #804BC3;
            .text1{
                color: #fff;
            }
            .line{
                width: 80px;
                height: 1px;
                background: #804BC3;
            } 
           
        }
        .list-item{
            cursor: pointer;
            height: 90px;
            width: 100%;
            // border-top: 1px solid #EEEEEE;
            padding-bottom: 9px ;
            box-sizing: border-box;
            .item-img{
                width: 40px;
                height: 40px;
                margin-top: 15px;

            }
            .line{
                width: 76px;
                height: 1px;
                background: #EEEEEE;
                
            }
            .text1{
                
                font-weight: 400;
                font-size: 20px;
                color: #000000;
                line-height: 20px;
            }
           
        }
	}
}
</style>
