
import instance from '@/utils/request.js'

// 把用户信息和房屋类型里面的东西都提交给后台
export function postmobile(obj) {
  return instance({
    url: '/portal/site/marketing/data',
    method: 'post',
    data: JSON.stringify(obj)
  })
}

// 获取营销计划的官网分页列表
export function getactivity(partnersCode) {
  return instance({
    url: '/portal/marketing/plan/partnersCode',
    method: 'get',
    params: { partnersCode: partnersCode }
  })
}

// 根据营销计划分页获取的官网id请求需要的4个代码
export function getactivityId(id) {
  return instance({
    url: '/portal/marketing/plan/' + id,
    method: 'get'
  })
}

// 获取营销计划的官网分页列表
export function getPopularActivities() {
  return instance({
    url: '/portal/advertisement/popularActivities/',
    method: 'get',
    params: { isMobile: 0 }
  })
}
