import store from '@/store/index'
import { getDesList,remote } from "@/api/dict";
import { getSalesRoomNo } from '@/api/product'
import { getDesRankDict,getCityDict } from '@/api/dict'
import { getTemplateData } from '@/api/template'
import { getCaseData } from '@/api/case'
import { getNewsData } from '@/api/news'

/**
 * 缓存设计师列表数据
 */
let isDesDict = false
let desDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}
export async function useDesDict () {
    const list = store.state.desList;
    if (list && list.length >0) {
        return list
    }else if(!isDesDict){
        try{
            isDesDict = true
            const res = await getDesList()
            if(res.code===0){
                store.commit('setDesList',res.data);
                return res.data
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isDesDict = false;  
            if (desDictlLock.status) {
                desDictlLock.resolve(); 
                desDictlLock.status = false;
            }
        }
    }else{
        if (!desDictlLock.status) {
            desDictlLock.status = true;
            desDictlLock.promise = new Promise(resolve => {
                desDictlLock.resolve = resolve;
            });
        }
        await desDictlLock.promise; 
        return store.state.desList;
    }
}

/**
 * 缓存模板列表数据
 */
let isTemDict = false
let temDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}
export async function useTemDict (params) {
    const list = store.state.temList;
    const temParams = store.state.temParams
    if (list && (list.length >0)  && (JSON.stringify(temParams)!=JSON.stringify(params))) {
        return list
    }else if(!isTemDict){
        try{
            isTemDict = true
            const res = await getTemplateData(params)
            if(res.code===0){
                store.commit('setTemInfo',{temList:res.data.records,temParams:params});
                return res.data.records
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isTemDict = false;  
            if (temDictlLock.status) {
                temDictlLock.resolve(); 
                temDictlLock.status = false;
            }
        }
    }else{
        if (!temDictlLock.status) {
            temDictlLock.status = true;
            temDictlLock.promise = new Promise(resolve => {
                temDictlLock.resolve = resolve;
            });
        }
        await temDictlLock.promise; 
        return store.state.temList;
    }
}

/**
 * 缓存案例列表数据
 */
let isCaseDict = false
let caseDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}
export async function useCaseDict (params) {
    const list = store.state.caseList;
    const caseParams = store.state.caseParams
    if (list && (list.length >0)  && (JSON.stringify(caseParams)!=JSON.stringify(params))) {
        return list
    }else if(!isCaseDict){
        try{
            isCaseDict = true
            const res = await getCaseData(params)
            if(res.code===0){
                store.commit('setTemInfo',{caseList:res.data.records,caseParams:params});
                return res.data.records
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isCaseDict = false;  
            if (caseDictlLock.status) {
                caseDictlLock.resolve(); 
                caseDictlLock.status = false;
            }
        }
    }else{
        if (!caseDictlLock.status) {
            caseDictlLock.status = true;
            caseDictlLock.promise = new Promise(resolve => {
                caseDictlLock.resolve = resolve;
            });
        }
        await caseDictlLock.promise; 
        return store.state.caseList;
    }
}
/**
 * 缓存新闻列表数据
 */
let isNewsDict = false
let newsDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}
export async function useNewsDict (params) {
    const list = store.state.newsList;
    if (list && list.length >0  ) {
        return list
    }else if(!isNewsDict){
        try{
            isNewsDict = true
            const res = await getNewsData(params)
            if(res.code===0){
                store.commit('setNewsList',res.data.records);
                return res.data.records
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isNewsDict = false;  
            if (newsDictlLock.status) {
                newsDictlLock.resolve(); 
                newsDictlLock.status = false;
            }
        }
    }else{
        if (!newsDictlLock.status) {
            newsDictlLock.status = true;
            newsDictlLock.promise = new Promise(resolve => {
                newsDictlLock.resolve = resolve;
            });
        }
        await newsDictlLock.promise; 
        return store.state.newsList;
    }
}
/**
 * 缓存设计风格列表
 */
let isDesStyleDict = false
let desStyleDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}

export async function useDesStyleDict () {
    const list  = store.state.desStyleList;
    if (list && list.length >0) {
        return list
    }else if(!isDesStyleDict){
        try{
            isDesStyleDict = true
            const res = await remote('design_style')
            if(res.code===0){
                store.commit('setDesStyleList',res.data);
                return res.data
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isDesStyleDict = false;  
            if (desStyleDictlLock.status) {
                desStyleDictlLock.resolve(); 
                desStyleDictlLock.status = false;
            }
        }
    }else{
        if (!desStyleDictlLock.status) {
            desStyleDictlLock.status = true;
            desStyleDictlLock.promise = new Promise(resolve => {
                desStyleDictlLock.resolve = resolve;
            });
        }
        await desStyleDictlLock.promise; 
        return store.state.desStyleList;
    }
}

/**
 * 缓存户型类型列表
 */
let isHousetypeDict = false
let housetypeDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}

export async function useHousetypeDict () {
    const list  = store.state.housetypeList;
    if (list && list.length >0) {
        return list
    }else if(!isHousetypeDict){
        try{
            isHousetypeDict = true
            const res = await remote('house_type')
            if(res.code===0){
                store.commit('setHousetypeList',res.data);
                return res.data
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isHousetypeDict = false;  
            if (housetypeDictlLock.status) {
                housetypeDictlLock.resolve(); 
                housetypeDictlLock.status = false;
            }
        }
    }else{
        if (!housetypeDictlLock.status) {
            housetypeDictlLock.status = true;
            housetypeDictlLock.promise = new Promise(resolve => {
                housetypeDictlLock.resolve = resolve;
            });
        }
        await housetypeDictlLock.promise; 
        return store.state.housetypeList;
    }
}

/**
 * 缓存面积类型列表
 */
let isHouseAreasDict = false
let houseAreasDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}

export async function useHouseAreasDict () {
    const list  = store.state.houseAreasList;
    if (list && list.length >0) {
        return list
    }else if(!isHouseAreasDict){
        try{
            isHouseAreasDict = true
            const res = await remote('house_area')
            if(res.code===0){
                store.commit('setHouseAreasList',res.data);
                return res.data
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isHouseAreasDict = false;  
            if (houseAreasDictlLock.status) {
                houseAreasDictlLock.resolve(); 
                houseAreasDictlLock.status = false;
            }
        }
    }else{
        if (!houseAreasDictlLock.status) {
            houseAreasDictlLock.status = true;
            houseAreasDictlLock.promise = new Promise(resolve => {
                houseAreasDictlLock.resolve = resolve;
            });
        }
        await houseAreasDictlLock.promise; 
        return store.state.houseAreasList;
    }
}
/**
 * 缓存门店列表数据
 */
let isSalesRoomDict = false
let salesRoomDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}

export async function useSalesRoomDict () {
    const list  = store.state.salesRoomList;
    if (list && list.length >0) {
        return list
    }else if(!isSalesRoomDict){
        try{
            isSalesRoomDict = true
            const res = await getSalesRoomNo()
            if(res.code===0){
                store.commit('setSaleRoomList',res.data.records);
                return res.data.records
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isSalesRoomDict = false;  
            if (salesRoomDictlLock.status) {
                salesRoomDictlLock.resolve(); 
                salesRoomDictlLock.status = false;
            }
        }
    }else{
        if (!salesRoomDictlLock.status) {
            salesRoomDictlLock.status = true;
            salesRoomDictlLock.promise = new Promise(resolve => {
                salesRoomDictlLock.resolve = resolve;
            });
        }
        await salesRoomDictlLock.promise; 
        return store.state.salesRoomList;
    }
}


/**
 * 缓存admin字典数据
 */
let isDict = false
let dictlLock  = {
    resolve:null,
    promise:null,
    status:false
}

export async function useDesRankDict () {
    const list  = store.state.dictList;
    if (list && list.length >0) {
        return list
    }else if(!isDict){
        try{
            isDict = true
            const res = await getDesRankDict()
            if(res.code===0){
                store.commit('setDictList',res.data);
                return res.data
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isDict = false;  
            if (dictlLock.status) {
                dictlLock.resolve(); 
                dictlLock.status = false;
            }
        }
    }else{
        if (!dictlLock.status) {
            dictlLock.status = true;
            dictlLock.promise = new Promise(resolve => {
                dictlLock.resolve = resolve;
            });
        }
        await dictlLock.promise; 
        return store.state.dictList;
    }
}

/**
 * 缓存城市信息数据
 */
let isCityInfo = false
let cityInfoLock  = {
    resolve:null,
    promise:null,
    status:false
}

export async function useCityInfo () {
    const cityStoreList  = store.state.cityStoreList;
    if (cityStoreList && cityStoreList.length >0) {
        return cityStoreList
    }else if(!isCityInfo){
        try{
            isCityInfo = true
            const resStore = await getSalesRoomNo()
            const resCity = await getCityDict()
            if(resStore.code==0 && resCity.code==0){
                const resList = []
                resCity.data.map(item=>{
                    if(item.cityCode=='310100' || item.cityCode=='410100'){
                        console.log("item------",item);
                        const filterList = resStore.data.records.filter(it=>it.city==item.cityCode).map(filtItem=>({
                            value:filtItem.showRoomNo,
                            label:filtItem.showRoomName
                        }))
                        resList.push(
                            {
                                value:item.cityCode,
                                label:item.cityCode=='310100' ? '上海市':item.name ,
                                children:filterList
                            }
                        )
                    }
                })
                console.log("resList----",resList);
                store.commit('setCityStore',resList);
                return resList
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isCityInfo = false;  
            if (cityInfoLock.status) {
                cityInfoLock.resolve(); 
                cityInfoLock.status = false;
            }
        }
    }else{
        if (!cityInfoLock.status) {
            cityInfoLock.status = true;
            cityInfoLock.promise = new Promise(resolve => {
                cityInfoLock.resolve = resolve;
            });
        }
        await cityInfoLock.promise; 
        return store.state.cityStoreList
    }
}


/**
 * 缓存城市字典
 */
let isCityDict = false
let cityDictlLock  = {
    resolve:null,
    promise:null,
    status:false
}

export async function useCityDict () {
    const list  = store.state.cityDict;
    if (list && list.length >0) {
        return list
    }else if(!isCityDict){
        try{
            isCityDict = true
            const res = await getCityDict()
            if(res.code===0){
                const resList = res.data.filter(item=>{
                    if(item.cityCode=='310100' || item.cityCode=='410100'){
                        return true
                    }
                })
                store.commit('setCityDict',resList);
                return resList
            }
        }
        catch(err){
            console.log("err",err);
        }
        finally{
            isCityDict = false;  
            if (cityDictlLock.status) {
                cityDictlLock.resolve(); 
                cityDictlLock.status = false;
            }
        }
    }else{
        if (!cityDictlLock.status) {
            cityDictlLock.status = true;
            cityDictlLock.promise = new Promise(resolve => {
                cityDictlLock.resolve = resolve;
            });
        }
        await cityDictlLock.promise; 
        return store.state.cityDict;
    }
}