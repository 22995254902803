export default {
    tenantId:2,
    // tenantId:3,
    cityList:[
        {
            // hostname:'http://q.qianjiamei.cn',
            // indexPath:'http://q.qianjiamei.cn',
            hostname:'https://www.qianjiamei.cn',
            indexPath:'https://www.qianjiamei.cn',
            name:'全国',
            cityCode:'00'
        },
        {
            hostname:'http://sh.qianjiamei.cn',
            indexPath:'http://sh.qianjiamei.cn/#/sh',
            name:'上海',
            cityCode:'310100'
        },
        {
            hostname:'http://zz.qianjiamei.cn',
            indexPath:'http://zz.qianjiamei.cn/#/zz',
            name:'郑州',
            cityCode:'410100'
        },
    ]
}