import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    curCityInfo:{
        value:'00',
        label:'全国'
    },
    // cityStoreMap:null,
    cityStoreList:[],
    // cityList:[],
    cityDict:[],


    desList:[],
    temList:[],
    newsList:[],
    temParams:{},
    caseList:[],
    caseParams:{},
    desStyleList:[],
    housetypeList:[],
    houseAreasList:[],
    salesRoomList:[],
    dictList:[]
  },
  mutations: {
    setCityDict(state,payload){
        state.cityDict = payload
    },
    setCurCity(state,payload){
        state.curCityInfo = payload
    },
    setCityStore(state,payload){
        
        state.cityStoreList = payload
    },
    setDesList(state,payload){
        state.desList = payload
    },
    setNewsList(state,payload){
        state.newsList = payload
    },
    setDesStyleList(state,payload){
        state.desStyleList = payload
    },
    setHousetypeList(state,payload){
        state.housetypeList = payload
    },
    setHouseAreasList(state,payload){
        state.houseAreasList = payload
    },
    setSaleRoomList(state,payload){
        state.salesRoomList = payload
    },
    setDictList(state,payload){
        state.dictList = payload
    },
    
    setTemInfo(state,payload){
        state.temList = payload.temList
        state.temParams = payload.temParams
    },
    setCaseInfo(state,payload){
        state.caseList = payload.caseList
        state.caseParams = payload.caseParams
    },

  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({
    paths: ['curCityInfo','cityDict','desStyleList','housetypeList','houseAreasList','dictList']
  })],
})
