import instance from '@/utils/request.js'
import store from '@/store/index.js'
/**
 * 获取广告页面的banner
 */
export function getadData({ current, size, advertisementSpace, isMobile }) {
	const params = {
		current,
		size,
		isMobile,
		status: 1,
		advertisementSpace,
		areaCode: store.state.curCityInfo.value,
        orderBy:"sort asc"
	}
    if (store.state.curCityInfo.value == '00') {
        delete params.areaCode
        params.isHeadOffice = 1
    }
	return instance({
		url: '/portal/advertisement/page',
		method: 'get',
		params,
	})
}
/**
 * 获取媒体
 */
export function getmedia() {
	return instance({
		url: '/portal/marketing/partners/page',
		method: 'get',
	})
}
