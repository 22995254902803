
import request from '@/utils/request'

// 查询所有设计师
export function getDesList () {
  return request({
    url: '/portal/designer/queryList',
    method: 'get',
  })
}
//获取admin 字典数据
export function getDesRankDict () {
    return request({
    url: '/admin/dict/type/designer_rank',
      method: 'get',
    })
}

//获取城市字典
export function getCityDict() {
    return request({
        url: '/admin/region/list/byLevel?level=city',
        method: 'get',
    })
}

// export function fetchList (query) {
//   return request({
//     url: '/admin/dict/page',
//     method: 'get',
//     params: query
//   })
// }

// export function fetchItemList (query) {
//   return request({
//     url: '/admin/dict/item/page',
//     method: 'get',
//     params: query
//   })
// }

// export function addItemObj (obj) {
//   return request({
//     url: '/admin/dict/item',
//     method: 'post',
//     data: obj
//   })
// }

// export function getItemObj (id) {
//   return request({
//     url: '/admin/dict/item/' + id,
//     method: 'get'
//   })
// }

// export function delItemObj (id) {
//   return request({
//     url: '/admin/dict/item/' + id,
//     method: 'delete'
//   })
// }

// export function putItemObj (obj) {
//   return request({
//     url: '/admin/dict/item',
//     method: 'put',
//     data: obj
//   })
// }

// export function addObj (obj) {
//   return request({
//     url: '/admin/dict/',
//     method: 'post',
//     data: obj
//   })
// }

// export function getObj (id) {
//   return request({
//     url: '/admin/dict/' + id,
//     method: 'get'
//   })
// }

// export function delObj (row) {
//   return request({
//     url: '/admin/dict/' + row.id,
//     method: 'delete'
//   })
// }

// export function putObj (obj) {
//   return request({
//     url: '/admin/dict/',
//     method: 'put',
//     data: obj
//   })
// }

export function remote (type) {
  return request({
    url: '/admin/dict/type/' + type,
    method: 'get'
  })
}

// export function refreshCache () {
//   return request({
//     url: '/admin/dict/sync',
//     method: 'put'
//   })
// }
